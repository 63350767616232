import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  titleTemplate,
  style,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <>
            <Helmet
                htmlAttributes={{
                  lang,
                }}
                style={style}
                title={title}
                titleTemplate={
                    titleTemplate || `%s | ${data.site.siteMetadata.title}`
                }
                script={[
                  {
                    src: 'https://psono.com/a.js',
                    type: 'text/javascript',
                  },
                  // {
                  //   type: 'text/javascript',
                  //   innerHTML: '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
                  //       'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
                  //       'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
                  //       '\'https://a.esaqa.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
                  //       '})(window,document,\'script\',\'dataLayer\',\'GTM-N6X74VQ3\');',
                  // },
                  // {
                  //   src: 'https://a.esaqa.com/a.js',
                  //   type: 'text/javascript',
                  // },
                  // {
                  //   type: 'text/javascript',
                  //   innerHTML: 'window.yourObject = { it: "works" }',
                  // },
                ]}
                meta={[
                  {
                    name: `description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:title`,
                    content: title,
                  },
                  {
                    property: `og:description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:type`,
                    content: `website`,
                  },
                  {
                    name: `twitter:card`,
                    content: `summary`,
                  },
                  {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.author,
                  },
                  {
                    name: `twitter:title`,
                    content: title,
                  },
                  {
                    name: `twitter:description`,
                    content: metaDescription,
                  },
                ]
                    .concat(
                        keywords.length > 0
                            ? {
                              name: `keywords`,
                              content: keywords.join(`, `),
                            }
                            : []
                    )
                    .concat(meta)}
            ></Helmet>
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
